import lozad from 'lozad';
import posthog from 'posthog-js';
import '@fontsource/gentium-book-basic/latin-700.css'; // Weight 700.
import Cookies from 'js-cookie';

// gatsby-browser.js
const { observe } = lozad('.lozad', {
  loaded: (el) => {
    const { objectFit: finalObjectFit } = el.dataset;

    /**
     * TODO：
     * 这里是为了解决loading占位图不会适应，铺满所有尺寸的图片的问题，
     * 可以先将loading图的object-fit设置为cover,
     * 后面通过设置data-object-fit设置最终图片的object-fit
     */
    if (el.nodeName === 'IMG' && el instanceof HTMLImageElement) {
      el.onload = () => {
        if (typeof finalObjectFit !== 'undefined') {
          el.style.objectFit = finalObjectFit;
        }
        el.classList.add('fade');
      };
    } else if (el.nodeName === 'VIDEO' && el instanceof HTMLVideoElement) {
      el.addEventListener('loadeddata', () => {
        if (typeof finalObjectFit !== 'undefined') {
          el.style.objectFit = finalObjectFit;
        }
        el.classList.add('fade');
      });
    } else {
      el.classList.add('fade');
    }
  },
});

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    posthog.init(process.env.GATSBY_POSTHOG_API_KEY, {
      api_host: 'https://us.i.posthog.com', // 如果是自托管，请改为你的 PostHog 实例地址
      cross_subdomain_cookie: true,
      capture_pageview: false,
    });
  }
};

const UTM_PARAMS = {
  utm_source: 'utm_source',
  utm_medium: 'utm_medium',
  utm_campaign: 'utm_campaign',
  utm_term: 'utm_term',
};

const UTM_STORE_KEY = 'notta_utm_params';

const storeUTMParams = (url) => {
  const searchParams = new URLSearchParams(url.split('?')[1] || '');
  const utmParams = Object.fromEntries(
    Object.values(UTM_PARAMS)
      .map((param) => [param, searchParams.get(param)])
      .filter(([_, value]) => value !== null),
  );

  if (Object.keys(utmParams).length > 0) {
    try {
      Cookies.set(UTM_STORE_KEY, JSON.stringify(utmParams), { domain: '.notta.ai' });
    } catch (e) {
      console.error('Failed to store UTM params:', e);
    }
  }
};

export const onRouteUpdate = ({ location }) => {
  storeUTMParams(location.href);
  observe();
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;

  const excludeRoute = ['/en/changelog'];

  if (excludeRoute.indexOf(pathname) !== -1) {
    return false;
  }

  return true;
};
